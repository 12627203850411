<!-- Portfolio start -->
<section id="portfolio" class="portfolio portfolio-box">
  <div class="container">
    <div class="row">
      <div class="col-md-12 heading text-center">
        <span class="icon-pentagon wow bounceIn"><i class="fa fa-university"></i></span>
        <h2 class="title2 mt-2"> Dirección Institucional
          de Posgrado e investigación
          <span class="title-desc">Servicios DIPI</span>
        </h2>
      </div>
    </div> <!-- Title row end -->

  
    <div class="row isotope" id="isotope">
  <div class="col-sm-6 col-md-4 col-lg-4 web-design isotope-item">
  <div class="grid">
    <a [routerLink]="['/convoexterno']">
    <figure class="m-0 effect-oscar overlay-fondo">
    <img src="./assets/images/mainpage/img1-convoex.png" alt="">
    <figcaption>
      <h3>Convocatorias externas </h3>
      <a class="link icon-pentagon"  >
        <i class="fa fa-link"></i></a>
<!--       <a class="view icon-pentagon" data-rel="prettyPhoto" href="./assets/images/portfolio/portfolio-bg1.jpg"><i
        class="fa fa-search"></i></a> -->
    </figcaption>
    </figure>
    </a>
  </div>
  </div><!-- Isotope item end -->
  
  <div class="col-sm-6 col-md-4 col-lg-4 web-design isotope-item">
  <div class="grid">    
    <a [routerLink]="['/exaujedprincipal']">
    <figure class="m-0 effect-oscar overlay-fondo"> 
    <img src="./assets/images/mainpage/xatec.png"  alt="">
    <figcaption>
      <h3>Exa-UJED</h3>
      <a class="link icon-pentagon"  [routerLink]="['/exaujedprincipal']">
        <i class="fa fa-link"></i></a>
    </figcaption>
    </figure> 
  </a>
  </div>
  </div><!-- Isotope item end -->
  
  <div class="col-sm-6 col-md-4 col-lg-4 joomla isotope-item">
  <div class="grid">
    <a [routerLink]="['/resultadosdipi']">
    <figure class="m-0 effect-oscar overlay-fondo">
    <img src="./assets/images/mainpage/img3-resu.png" alt="">
    <figcaption>
      <h3>RESULTADOS DIPI</h3>
      <a class="link icon-pentagon" [routerLink]="['/resultadosdipi']">
        <i class="fa fa-link"></i></a>
    </figcaption>
    </figure>
  </a>
  </div>
  </div><!-- Isotope item end -->

  <div class="col-sm-6 col-md-4 col-lg-4 joomla isotope-item">
    <div class="grid">
      <a href="https://6fe128-22.myshopify.com/">
      <figure class="m-0 effect-oscar overlay-fondo">
      <img src="./assets/images/mainpage/maestrialinea.png" alt="">
      <figcaption>
        <h3>MAESTRIAS EN LINEA</h3>
        <a class="link icon-pentagon" href="https://6fe128-22.myshopify.com/">
          <i class="fa fa-link"></i></a>
      </figcaption>
      </figure>
    </a>
    </div>
    </div><!-- Isotope item end -->

  </div>
  <!-- Content row end -->
  
  </div><!-- Container end -->
  </section><!-- Portfolio end -->