
import { Component, ElementRef, OnInit, ViewChild ,Renderer2 } from '@angular/core';

import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Dinvestigadores } from 'src/app/models/dinvestigadores';

import { SipuService } from 'src/app/services/sipu.service';

@Component({
  selector: 'app-investigadoresm',
  templateUrl: './investigadoresm.component.html',
  styleUrls: ['./investigadoresm.component.scss']
})
export class InvestigadoresmComponent implements OnInit {
  investigadores: any[] = [];
  currentIndex = 0;
  visibleInvestigadores: any[] = [];


  constructor(private _ss: SipuService, private renderer: Renderer2) { }

/*   ngOnInit(): void {
    this._ss.listarInvestigador().subscribe(investigadores => {
      this.investigadores = investigadores;  
      console.log("INVESIGADORES", this.investigadores);
    },
    error => {
      console.log(error);
    });
  } */

    ngOnInit(): void {
      this._ss.listarInvestigador().subscribe(
        (investigadores: Dinvestigadores[]) => {
          this.investigadores = investigadores;
          console.log(this.investigadores);
          this.visibleInvestigadores = this.investigadores.slice(0, 4); // Mostrar los primeros 4 inicialmente
        },
        (error) => {
          console.log(error);
        }
      );
    }

  
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.investigadores.length;
      this.updateVisibleInvestigadores();
    }
  
    previous() {
      this.currentIndex = (this.currentIndex - 1 + this.investigadores.length) % this.investigadores.length;
      this.updateVisibleInvestigadores();
    }
  
    updateVisibleInvestigadores() {
      this.visibleInvestigadores = [];
      for (let i = 0; i < 4; i++) {
        this.visibleInvestigadores.push(this.investigadores[(this.currentIndex + i) % this.investigadores.length]);
      }
    }
  
}
