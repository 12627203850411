<!-- src/app/components/image-slider/image-slider.component.html -->



<section class="speakers-full-width">
	<div class="container-fluid p-0">
		<div class="row">
			<div class="col-md-12 heading">
				<span class="title-icon float-left"><i class="fa fa-weixin"></i></span>
				<a [routerLink]="['/investigadores']">
					<h2 class="title">Investigadores <span class="title-desc">Conoce nuestro equipo</span></h2>
				</a>
			</div>
		</div><!-- Title row end -->

		<div class="investigadores-header text-center">
			<h4>Conoce a los investigadores de la UJED</h4>
			<p>
			  Descubre el trabajo de nuestros destacados investigadores, miembros del Sistema Nacional de Investigadores (SNI), quienes con su labor impulsan el desarrollo científico y académico. ¡Explora sus perfiles y conoce sus contribuciones!
			</p>
		  </div>

		<div class="row">
			<div class="col-12">
				<div class="slider-container">
					<button (click)="previous()" class="slider-button">Anterior </button>
				
					<!-- <div class="slider-images">
						<img *ngFor="let investigador of visibleInvestigadores"  src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{ investigador?.vinv_invid }}"
							alt="Imagen de Ejemplo" />
						
					</div> -->
					<div class="slider-images">
						<div class="slider-item" *ngFor="let investigador of visibleInvestigadores">
						  <img src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{ investigador?.vinv_invid }}"
							   alt="Imagen de Ejemplo" />
						  <div class="overlay-text">
							<h5>{{ investigador?.vinv_nombre }}</h5>
							<p>{{ investigador?.vinv_titulo }}</p>
							<a [routerLink]="['/investigador', investigador?.vinv_invid]" class="btn btn-outline-white white">Ver</a>
							<ul class="list-inline">
							  <li class="list-inline-item"><a href="https://themefisher.com/"><i class="fa fa-facebook"></i></a></li>
							  <li class="list-inline-item"><a href="https://themefisher.com/"><i class="fa fa-twitter"></i></a></li>
							  <li class="list-inline-item"><a href="https://themefisher.com/"><i class="fa fa-linkedin"></i></a></li>
							</ul>
						  </div>
						</div>
					  </div>
				
					<button (click)="next()" class="slider-button">Siguiente</button>
				</div>
			</div>
		</div>

				<!-- Botón centrado debajo del slider -->
		<div class="text-center mt-4">
			<a [routerLink]="['/investigadores']" class="btn btn-link conoce-investigadores-btn">Conoce a los investigadores</a>
		</div>


	</div>
</section>